import logoLink from '../resources/lovion.svg';
import companyLogoLink from '../resources/lovion.svg';
import footerLogoLink from '../resources/lovion_emeye-t-inside_logo.svg';

export const cssVariables = {
    primary_light: '#FFC299',
    primary: '#FF8C00',
    text_on_primary: 'white',
    text_on_primary_caller: 'white',

    secondary: '#333333',
    text_on_secondary: 'white',
    focus_active: '#FF8C00',

    font: 'Arial, Helvetica, sans-serif',
    link_color: '#FF8C00',
    font_color: '#000',
    font_weight: '700',

    border_radius: '20px',
    border_width: '2px',
    border_style: 'solid',
    border_radius_narrow: '10px',
    border_radius_super_narrow: '5px',
    border_width_nav: '8px',

    disabled: '#dbdbdb',

    gridbox_header_bg: '#222E83',
    gridbox_header_text: '#ffffff',
    gridbox_padding: '8px',
    gridbox_header_padding: '8px',

    red: '#DB1C1C',
    green: '#008238',
    connecting: '#c0c0c0',
    connected: '#008238',
    aborted: '#DB1C1C',
    paused: '#FFE100',

    header_footer_seperator: '#FF8C00',
    panel_highlight: '#222E83',
    chat_background_right: '#FFD1B2',
    chat_background_left: '#dbdbdb',

    active: '#FF8C00',
    inactive: '#333333',
    text_on_active: 'white',
    text_on_inactive: 'white',

    dropdown_background: '#FFC299',

    phonebook_dropdown: '#FFC299',
    phonebook_dropdown_svg: '#000',

    pointer: '#FF8C00',

    toggle_switch: 'rgb(255, 255, 255)',
};

export const logo = logoLink;
export const companyTitle = 'Lovion';
export const companyLogo = companyLogoLink;
export const footerLogo = footerLogoLink;
